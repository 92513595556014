<style scoped>
.person-link{
  margin-top: -16px;
  padding-bottom: 10px;
  text-align: center;
}
.person-detail{
  width: 100%;
}
.person-item-left{
  background-color: #FA8B59;
  float: right;
}
.person-item-left-content{
  float: right;
  position: absolute;
  right: 2px;
}
.person-item-right{
  background-color: #4285F4;
  padding-left: 1px;
  color: #fff;
  white-space: nowrap;
}
</style>

<template>
    <div>
        <Divider dashed><span class="divider-text">个人能力</span></Divider>
        <div class="person-link">
            <a style="font-size: xx-small" @click="handleChangePerson">
                <span v-if="!showMorePerson"><Icon type="md-arrow-round-down" />点击展开个人能力<Icon type="md-arrow-round-down" /></span>
                <span v-else><Icon type="md-arrow-round-up" />点击收起个人能力<Icon type="md-arrow-round-up" /></span>
            </a>
        </div>
        <div v-show="showMorePerson">
          <Row  class="text-center">
            <i-col span="8">签约金额</i-col>
            <i-col span="8">目标金额</i-col>
            <i-col span="8">完成率</i-col>
          </Row>
          <Row class="text-center m-b-10 person-item-title">
            <i-col span="8">{{formatMoney(kpi.contract)}}</i-col>
            <i-col span="8">{{formatMoney(kpi.target)}}</i-col>
            <i-col span="8" class="text-orange text-bold">{{complateRate}}</i-col>
          </Row>
          <Row class="text-center">
            <i-col span="8">实回款</i-col>
            <i-col span="8">应回款</i-col>
            <i-col span="8">回款率</i-col>
          </Row>
          <Row class="text-center m-b-10 person-item-title">
            <i-col span="8">{{formatMoney(kpi.actualAmount)}}</i-col>
            <i-col span="8">{{formatMoney(kpi.targetAmount)}}</i-col>
            <i-col span="8" class="text-orange text-bold">{{moneyRate}}</i-col>
          </Row>
          <h3 class="text-center">综合排名： {{myRanking}}</h3>

          <div style="width:290px;height:200px;" id="personReport1"></div>
          <div class="person-detail">
            <Row class="text-center p-b-5">
              <i-col span="9" offset="6">我</i-col>
              <i-col span="9">人均</i-col>
            </Row>
            <Row class="p-b-5" v-for="(ab,index) in abilitys" :key="index">
              <i-col span="6">{{ab.name}}</i-col>
              <i-col span="9" class="text-right">
                <div class="person-item-left" :style="{'width':ab.leftRate, 'color':'#FA8B59'}">.</div>
                <div class="person-item-left-content">{{ab.left}}</div>
              </i-col>
              <i-col span="9">
                <div  class="person-item-right" :style="{'width':ab.rightRate}">{{ab.right}}</div>
              </i-col>
            </Row>
          </div>

        </div>
    </div>
</template>

<script>
import { countKpiTargetAmount, countKpiContractAmount, getUserDebtFinishRate, listPersonalAbilityRanking } from '@/api/dw/ability'
import { ParseDate } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

import * as echarts from 'echarts'

export default {
  data () {
    return {
      showMorePerson: true,
      query: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: new Date().getFullYear() + '-01-01',
        endDate: ParseDate(new Date()),
        userId: this.$store.getters.token.userInfo.userId
      },
      kpi: {
        target: 0,
        contract: 0,
        actualAmount: 0,
        targetAmount: 0
      },
      abilitys: [],
      abilityRanking: null,
      dataSourcePie: null
    }
  },
  created () {
    this.initPageData()
    this.initAbilitys()
  },
  methods: {
    handleChangePerson () {
      this.showMorePerson = !this.showMorePerson
      if (this.showMorePerson) {
        this.dataSourcePie.resize()
      }

      this.$emit('on-more-person', this.showMorePerson)
    },
    initPageData () {
      // 目标额
      countKpiTargetAmount(this.query).then(res => {
        this.kpi.target = res
      })
      // 签约额
      countKpiContractAmount(this.query).then(res => {
        this.kpi.contract = res
      })
      // 应回款及回款金额
      getUserDebtFinishRate(this.query).then(res => {
        this.kpi.actualAmount = res.actualAmount
        this.kpi.targetAmount = res.targetAmount
      })
    },
    initAbilitys () {
      listPersonalAbilityRanking(this.query).then(res => {
        this.abilityRanking = res
        this.formatReportData(res)

        let leftLable
        let leftRate
        let rightLable
        let rightRate

        res.forEach(element => {
          if (element.name.indexOf('率') > -1) {
            leftLable = element.actualAmount + '%'
            leftRate = leftLable
            rightLable = element.targetAmount + '%'
            rightRate = rightLable
          } else if (element.name.indexOf('额') > -1) {
            // 谁的值大，就取谁的百分比
            leftLable = toMoney(element.actualAmount)
            leftRate = element.actualAmount >= element.targetAmount ? '100%' : element.actualAmount / element.targetAmount + '%'
            rightLable = toMoney(element.targetAmount)
            rightRate = element.actualAmount <= element.targetAmount ? '100%' : element.actualAmount / element.targetAmount + '%'
          } else {
            leftLable = element.actualAmount
            leftRate = element.actualAmount >= element.targetAmount ? '100%' : element.actualAmount / element.targetAmount + '%'
            rightLable = element.targetAmount
            rightRate = element.actualAmount <= element.targetAmount ? '100%' : element.actualAmount / element.targetAmount + '%'
          }

          this.abilitys.push(
            {
              name: element.name,
              left: leftLable, // 个人
              leftRate: leftRate,
              right: rightLable, // 人均
              rightRate: rightRate
            }
          )
        })
      })
    },
    formatReportData (data) {
      const labels = []
      const ownValue = { value: [], name: '我的能力排名', rank: [], title: [] }

      data.forEach(element => {
        // 添加标注信息
        labels.push({ name: element.name, max: 100 })
        ownValue.value.push((100 - element.rate).toFixed(2))
        ownValue.rank.push(element.number)
        ownValue.title.push(element.name)
      })

      this.initChart1(labels, ownValue)
    },
    initChart1 (labels, ownValue) {
      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('personReport1')) {
          this.dataSourcePie = echarts.init(document.getElementById('personReport1'))

          const option = {
            color: ['#FA8B59', '#4285F4'],
            tooltip: {
              trigger: 'axis',
              position: ['40%', '40%'],
              formatter: function (params, ticket, callback) {
                var showHtm = ''
                for (var i = 0; i < params.data.value.length; i++) {
                // 名称
                  var name = params.data.title[i]
                  // 排名
                  var value = params.data.rank[i]
                  showHtm += name + ' 排名：' + value + '<br>'
                }
                return showHtm
              }
            },
            radar: [
              {
                indicator: labels,
                radius: 70,
                axisName: {
                  fontSize: '10',
                  padding: [0, -6]
                }
              }
            ],
            grid: {
              top: '0%',
              left: '40%',
              right: '40%',
              bottom: '0%'
            },
            series: [
              {
                type: 'radar',
                tooltip: {
                  trigger: 'item'
                },
                areaStyle: {},
                data: [ownValue]
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
        }
      })
    },
    formatMoney (val) {
      return toMoney(val)
    }
  },
  computed: {
    complateRate () {
      if (this.kpi.target === 0) {
        return '100%'
      } else {
        return (this.kpi.contract / this.kpi.target * 100).toFixed(2) + '%'
      }
    },
    moneyRate () {
      if (this.kpi.targetAmount === 0) {
        return '0%'
      } else {
        return (this.kpi.actualAmount / this.kpi.targetAmount * 100).toFixed(2) + '%'
      }
    },
    myRanking () {
      if (this.abilityRanking === null) { return 1 }

      let amount = 0
      this.abilityRanking.forEach(element => {
        amount += element.number
      })

      return (amount / this.abilityRanking.length) < 1 ? 1 : parseInt(amount / this.abilityRanking.length)
    }
  }
}
</script>
