<template>
    <div>
        <ability @on-more-person="handleChangePerson"/>

        <message :showMorePerson="showMorePerson"/>
    </div>
</template>

<script>
import ability from './component/Ability'
import message from './component/Message'

export default {
  components: { ability, message },
  data () {
    return {
      showMorePerson: true
    }
  },
  methods: {
    handleChangePerson () {
      this.showMorePerson = !this.showMorePerson
    }
  }
}
</script>
