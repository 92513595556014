<style scoped>
.message-block{
  padding: 5px;
  margin: 4px 0;
}
.message-content{
  font-size: xx-small;
}
.message-new{
  float: right;
  font-size: xx-small;
  color: #FA8B59;
}
.message-button-active{
  color: #fff;
  background-color: #4285F4;
  width: 40px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.message-button{
  width: 40px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
</style>

<template>
    <div>
        <Divider dashed size="small"><span class="divider-text">消息</span></Divider>
        <Row class="p-t-10 p-b-5">
          <i-col span="4" v-for="category in messageCategorys" :key="category.id">
            <Badge :count="category.count" class-name="message-badge">
              <div :class="query.category === category.value?'message-button-active':'message-button'" @click="handleChageCategory(category.value)">{{category.name}}</div>
            </Badge>
          </i-col>
        </Row>

        <div class="message-block" v-for="message in messageList" :key="message.id">
          <p class="p-b-5"><strong>{{message.title}}</strong><span v-if="new Date(message.createTime) > today" class="message-new">新</span></p>
          <p class="message-content" v-for="(item, index) in message.content" :key="index">
            <span v-if="index!==0">{{item}}</span>
          </p>
          <p class="message-content text-right">{{message.createTime}}</p>
        </div>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total @on-change="handlePageChange"></Page>
        </div>
    </div>
</template>

<script>
import { getMessagePage, getMessageStatistics } from '@/api/message/message'
import { getDictList } from '@/api/sys/dict'

export default {
  props: {
    showMorePerson: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      messageCategorys: [],
      query: {
        pageNumber: 1,
        pageSize: 3,
        userId: this.$store.getters.token.userInfo.userId,
        category: ''
      },
      total: 0,
      messageList: []
    }
  },
  created () {
    this.initPageData()
    this.getMessage()
  },
  methods: {
    initPageData () {
      getMessageStatistics({ userId: this.$store.getters.token.userInfo.userId }).then(messageStatistics => {
        // 获取全部消息类型
        let newMessageCount
        getDictList({ key: 'message_category' }).then(res => {
          res.forEach(element => {
            newMessageCount = messageStatistics.find(x => x.category === element.value)

            if (newMessageCount) {
              this.messageCategorys.push(Object.assign({}, element, { count: newMessageCount.count }))
            } else {
              this.messageCategorys.push(Object.assign({}, element, { count: 0 }))
            }
          })
        })
      })
    },
    getMessage () {
      getMessagePage(this.query).then(res => {
        this.messageList = res.list.map(x => {
          x.content = x.content.split('\n')
          return x
        })
        this.total = res.totalRow
      })
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.getMessage()
    },
    handleChageCategory (category) {
      this.query.category = (this.query.category === category) ? '' : category
      this.query.pageNumber = 1
      this.getMessage()
    }
  },
  computed: {
    today () {
      const currentDate = new Date()
      const date = new Date(currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate() + ' 00:00:00')
      return date
    }
  },
  watch: {
    showMorePerson (val) {
      this.query.pageSize = val ? 3 : 7
      this.query.pageNumber = 1
      this.getMessage()
    }
  }
}
</script>
