import request from '@/utils/requestV2'
const qs = require('qs')

// 获取消息记录分页
export function getMessagePage (data) {
  return request({
    url: '/ooh-message/v1/message/get',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取最新消息汇总
export function getMessageStatistics (data) {
  return request({
    url: '/ooh-message/v1/message/statistics',
    method: 'post',
    data: qs.stringify(data)
  })
}
