import request from '@/utils/requestV2'
const qs = require('qs')

// 获取用户KPI设置相关的目标额
export function countKpiTargetAmount (data) {
  return request({
    url: '/ooh-dw/v1/dw/ability/countKpiTargetAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取用户的签约额和目标额
export function countKpiContractAmount (data) {
  return request({
    url: '/ooh-dw/v1/dw/ability/countKpiContractAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取销售员的应回款及回款金额
export function getUserDebtFinishRate (data) {
  return request({
    url: '/ooh-dw/v1/dw/ability/getUserDebtFinishRate',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取销售员的个人能力雷达图
export function getPersonalAbilityRadar (data) {
  return request({
    url: '/ooh-dw/v1/dw/ability/getPersonalAbilityRadar',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取销售员的个人能力雷达图-新接口
export function listPersonalAbilityRanking (data) {
  return request({
    url: '/ooh-dw/v1/dw/ability/listPersonalAbilityRanking',
    method: 'post',
    data: qs.stringify(data)
  })
}
